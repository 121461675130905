import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function ComingSoon() {
  return (
    <>
      <Helmet>
        <title>Kangaroo Project Management - Coming Soon</title>
        <meta
          name="title"
          content="Kangaroo Project Management - Coming Soon"
        />
        <meta
          name="description"
          content="Kangaroo, the all-in-one project management platform for creative teams and freelancers, is coming soon. Sign up to be notified of the launch!"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="w-full flex items-center justify-center h-screen flex-col bg-black">
        <div className="font-bold text-2xl flex items-center text-white">
          <div className="flex items-center mr-2">
            <div className="w-8 h-8 rounded-full bg-[#ca7eff]"></div>
            <div className="w-8 h-8 rounded-full -ml-5 bg-[#FFC300]"></div>
            <div className="w-8 h-8 rounded-full -ml-5 bg-[#0073ea]"></div>
          </div>
          Kangroo
        </div>
        <div className="font-semibold text-4xl text-white mt-7">
          We're Coming Soon!
        </div>
        <div className=" text-xl text-white/90 w-2/3 mt-3 text-center">
          Streamline workflows, boost collaboration, and deliver exceptional
          results with Kangaroo, the all-in-one project management platform
          designed to empower creative teams and freelancers.
        </div>
      </div>
    </>
  );
}

export default ComingSoon;
