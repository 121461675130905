import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DashboardMain, Home, Login, Signup } from "./Links";
import ComingSoon from "../Pages/ComingSoon";

function CreateRoutes() {
  return (
    <Router>
      <Routes>
        {/* Dashboard */}

        <Route
          path="/dashboard"
          element={
            <Suspense>
              <DashboardMain />
            </Suspense>
          }
        />

        {/*  */}
        <Route
          path="/auth/signup"
          element={
            <Suspense>
              <Signup />
            </Suspense>
          }
        />
        <Route
          path="/auth/login"
          element={
            <Suspense>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/Home"
          element={
            <Suspense>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <Suspense>
              <ComingSoon />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

export default CreateRoutes;
