import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Helmet } from "react-helmet";
import { FcGoogle } from "react-icons/fc";
import CreateRoutes from "./Routes/Routes";

function App() {
  return <CreateRoutes />;
}

export default App;
